   

import * as React from "react"
import { graphql, StaticQuery } from "gatsby"


function ComingSoon({data}) {

    return (
        <StaticQuery 
        query={graphql`
        query RealEstateListings2{
            allGraphCmsPageRealEstate {
                edges{
                  node {
                    id
                    pageTitle
                    headerImage {
                      id
                      imageAltText
                      imagePhoto {
                        gatsbyImageData(placeholder: BLURRED, quality: 60)
                        url
                      }
                    }
                  }
                }
              }
          }
        `}


    render={data => (
            <div className="coming-soon">
                <div className="header">
                {data.allGraphCmsPageRealEstate.edges.map(({ node: realestate }) => (
                <div className="header-image">
                <div className="header-overlay"></div>
                  <img src={realestate.headerImage.imagePhoto.url}
                                        quality={60}
                                        formats={["auto", "webp", "avif"]}
                                        alt={realestate.headerImage.imageAltText}
                    />
                    <div className="header-text">
                        <h1>Coming Soon</h1>
                    </div>
                
                </div> 
                ))}

                </div>
            <div className="listings">
                <h2>This page is currently under construction.</h2>
            </div>
            </div>
      )}
    />
  );
}

export default ComingSoon;



