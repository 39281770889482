import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import "./comingsoon.scss"

import ComingSoonPage from "./components/comingsoon"

const ComingSoon = () => (
  <Layout>
    <Seo title="Royal Palm Destinations | Royal Palm Destinations Real Estate " />
    <ComingSoonPage/>
  </Layout>
)

export default ComingSoon;
